.work-item{
  width:100%;
  display:flex;
  margin-bottom:60px;
  align-items:center;
  h3{
    margin-top:0;
    margin:0;
    line-height:40px;
  }
  p{
    font-size:18px;
    margin-top:0;
  }
  .rect{
    margin:10px 0;  
  }
  audio{
    width:100%;
  }
  & img:nth-child(2){
    width:50px;
  }
  & > img{
    width:330px;
    height:330px;
  }
  h4{
    margin-bottom:10px;
    font-size:16px;
    padding-left:5px;
  }
}

.work-content{
  padding-left:20px;
  width:100%;
  display: flex;
  flex-direction:column;
  min-height:80%;
  justify-content: space-around;
}

.loading{
  display:flex;
  align-items:center;
  span{
    font-size:16px;
    margin-left:10px;
    color:white;
  }
  &.hide{
    display: none;
  }
  &.show{
    display: flex;
  }
}

.audio-player{
  &.show{
    display: initial;
  }
  &.hide{
    display: none;
  }
}

.projects{
  .project > img{
    width:250px;
    height:250px;
  }
  button{
    margin-top:20px;
  }
  h3{
    max-width:80%;
  }
  h4{
    text-align: center;
    margin-top:100px;
    margin-bottom:50px;
    font-size:30px;
  }
  p{
    max-width:90%;
  }
}

@media (max-width: 768px) {
  .work-item{
    flex-direction:column;
    align-items:flex-start;
    padding:0 20px;
    & > img{
      max-width:100%;
      height:auto;
    }
  }
  .work-content{
    padding-left:0;
    min-height:auto;
  }
  .hero{
    padding:0 40px;
    margin-top:100px;
  }
  .projects{
    p{
      max-width:100%;
    }
    h3{
      max-width:100%;
    }
  }
}