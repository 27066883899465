/*Hero Component*/
.about{
  margin-top:100px;  
  display:flex;
  min-height:60vh;
  align-items:center;
  p{
    margin-top:10px;
    margin-bottom:0;
    font-size:24px;
    line-height:40px;
  }
  h1{
    margin-bottom:10px; 
    margin-top:0;
  }
  img{
    max-width:300px;
    border-radius:50%;
    margin-right:40px;
  }
  @media (max-width: 768px) {
    flex-direction:column;
  }
}
