/*Hero Component*/
.hero{
  margin-top:200px;  
  display:flex;
  flex-direction:column;
  min-height:60vh;
}

.hero p{
  margin-top:30px;
  margin-bottom:0;
}
.hero h1{
  margin-bottom:10px;  
}

/*Work Items*/
.work{
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
}
.pink-bg{
    background-color:#FC5081
  }

@media (max-width: 768px) {
  br{
    display: none;
  }
}