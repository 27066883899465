/*Global Styles*/
.container{
  max-width:1440px;
  padding:0 20px;
  margin:auto;
}
.container.small{
  max-width:920px;
}
.container.smaller{
  max-width:1200px;
}
@media (max-width: 768px) {
  .container{

  }
}

*{
  box-sizing:border-box;
}
body {
  background-color: white;
  color: black;
  font-family: "DM Sans", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  min-height:90vh;
  background-color:black;
  margin:0;
}
h1{
  font-size:50px;
  color:white;
  font-weight:300;
}
h3{
  font-size:24px;
  color:white;
}
h4{
  font-size:20px;
  color:white;
  margin:0;
}
p{
  font-size:30px;
  font-weight:200;
  color:white;
}
button{
  font-family: "DM Sans", serif;
  background-color:transparent;
  border:1px solid white;
  color:white;
  cursor:pointer;
  padding:10px 30px;
  font-size:14px;
  font-weight:600;
  transition: background-color .3s ease, color .3s ease;
  &:hover{
    background-color:white;
    color:black;
  }
}

nav .container{
  display:flex;
  align-items:center;
  justify-content:flex-end;
  padding-top:30px;
  padding:30px 30px 0 30px;
}
nav .container a{
  margin-right:20px;
  margin-bottom:10px;
}
nav img{
  max-width:100px;
}
nav .container a:first-child{
  margin-left:0;
  margin-right:auto;
  margin-bottom:0;
}
a{
  text-decoration:none;
  color:white;
  font-size:24px;
}
.wiggler{
  /*filter: drop-shadow(4px 4px 1px #ea0043);*/
}
.wiggler-bg{
  background-image:url('./assets/waves.svg');
  height: 160px;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  animation:slide;
  animation-duration:10s; 
  animation-fill-mode:forwards;
}

@keyframes slide{
  from{
    transform:translateX(0)
  }
  to{
    transform:translateX(-100%)
  }
}

/*Specific components live here*/

/*Footer*/
footer p,
footer a{
  font-size:18px;
  font-weight:200;
}
footer a{
  margin-right:20px;
}
footer .container{
  display:flex;
  align-items:center;
  padding:30px 30px 0 30px;
}

footer .links{
  margin-left:auto;
  margin-right:0;
}


/*Animations and utility classes*/
.fade-in{
  animation-name:fadeIn;
  animation-duration:.5s; 
  animation-iteration-count:1;
  animation-fill-mode:forwards;
  opacity:0;
}
.offset-1{
  animation-delay:.7s;
}
.offset-2{
  animation-delay:1.2s;
}
.offset-3{
  animation-delay:1.5s;
}

@keyframes fadeIn{
  from{
    opacity:0;
    transform:translateX(-20px);
  }
  to{
    opacity:1;
    transform:translateX(0);
  }
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
  color:white;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 18px;
    left: 18px;
    width: 4px;
    height: 4px;
    opacity: 0;
  }
  4.9% {
    top: 18px;
    left: 18px;
    width: 4px;
    height: 4px;
    opacity: 0;
  }
  5% {
    top: 18px;
    left: 18px;
    width: 4px;
    height: 4px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}

